import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ListingTemplate = ({ data }) => {
  const {
    streetAddress,
    askingPrice,
    fee,
    description,
    listingHemnetUrl,
    tenure,
    housingForm,
    images,
  } = data.hemnetApi.listing

  const imageUrl = images && images.images[0] && images.images[0].url
  const totalImages = images && images.total

  const Listing = styled.div`
    border: 1px solid lightgray;
    padding: 8px;
  `

  const ListingImageContainer = styled.div`
    position: relative;
    max-width: 640px;
    margin: 0 auto;
  `

  const ListingImage = styled.img`
    margin: 0 auto;
    width: 100%;
  `

  const ListingImageInformation = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.4);
    padding: 5px 15px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
  `

  return (
    <Layout>
      <SEO title={streetAddress} />
      <Listing>
        <ListingImageContainer>
          {imageUrl && (
            <ListingImage alt={`Bild på ${streetAddress}`} src={imageUrl} />
          )}
          <ListingImageInformation>
            {totalImages > 1 ? `${totalImages} bilder` : "1 bild"}
          </ListingImageInformation>
        </ListingImageContainer>

        <h1>{streetAddress}</h1>
        <dl>
          <dt>Utgångspris</dt>
          <dd>{(askingPrice && askingPrice.formatted) || "Pris saknas"}</dd>
          {fee && (
            <>
              <dt>Månadsavgift</dt>
              <dd>{fee.formatted}</dd>
            </>
          )}
          {tenure && (
            <>
              <dt>Upplåtelseform</dt>
              <dd>{tenure.name}</dd>
            </>
          )}
          {housingForm && (
            <>
              <dt>Bostadstyp</dt>
              <dd>{housingForm.name}</dd>
            </>
          )}
        </dl>

        <p>{description}</p>
        <a href={listingHemnetUrl}>Se bostadsannonsen på Hemnet</a>
      </Listing>
    </Layout>
  )
}

export default ListingTemplate

export const query = graphql`
  query HemneoStaticListingTemplateQuery($listingId: ID!) {
    hemnetApi {
      listing(id: $listingId) {
        streetAddress
        askingPrice {
          formatted
        }
        fee {
          formatted
        }
        tenure {
          name
        }
        housingForm {
          name
        }
        listingHemnetUrl
        ... on Hemnet_ActivePropertyListing {
          description
          images(limit: 1) {
            images {
              url(format: ITEMGALLERY_CUT)
            }
            total
          }
        }
        ... on Hemnet_Project {
          description
          images(limit: 1) {
            images {
              url(format: ITEMGALLERY_CUT)
            }
            total
          }
        }
        ... on Hemnet_ProjectUnit {
          description
          images(limit: 1) {
            images {
              url(format: ITEMGALLERY_CUT)
            }
            total
          }
        }
      }
    }
  }
`
